import { defineStore } from "pinia";
import http from "@utils/axios";
import { useAuthStore } from "./auth";

// const authStore = useAuthStore();

// interface INewItem {
//   data: ITask;
//   event: string;
//   model: string;
// }

interface IState {
  list: ITask[];
  filteredList: ITask[];
  filtered: boolean;
  lastFilter: IFilter | null;
}

interface IFilter {
  assignedTo: string;
  role_id: string;
  date: string;
}

interface ITask {
  role_id: string;
  assigned_by: string;
  by_event: boolean;
  deadline: string;
  id: number;
  order_id: number;
  order_number: string;
  status: "new" | "process" | "done";
  task_name: string;
  assigned_to_id: number;
  assigned_by_id: number;
  executor_name: string;
}

export const useTaskStore = defineStore("taskStore", {
  state: (): IState => ({
    list: [],
    filteredList: [],
    filtered: false,
    lastFilter: null,
  }),
  getters: {
    getTaskByStatus(): (status: "new" | "process" | "done") => ITask[] {
      return (status: any) =>
        this.filtered
          ? this.filteredList.filter((task) => task.status === status)
          : this.list.filter((task) => task.status === status);
    },
  },
  actions: {
    async setFilter(filter: IFilter) {
      if (!filter.date?.length && !filter.role_id && !filter.assignedTo) {
        this.lastFilter = filter;
        this.filtered = false;
        return;
      } else {
        await this.fetchList();
        const { assignedTo, role_id, date } = filter;
        const startDate = date?.length ? new Date(date[0]) : null;
        const endDate = date?.length ? new Date(date[1]) : null;
        this.filteredList = this.list.filter((task) => {
          const itemDate = new Date(task.deadline);
          const meetsRoleId = role_id ? task.role_id === role_id : true;
          let meetsAssignment = false;
          switch (assignedTo) {
            case "ToMe":
              meetsAssignment =
                task.assigned_to_id === useAuthStore().getUser?.id;
              break;
            case "ByMe":
              meetsAssignment =
                task.assigned_by_id === useAuthStore().getUser?.id;
              break;

            default:
              meetsAssignment = true;
              break;
          }
          const meetsDateRange =
            !startDate ||
            !endDate ||
            (itemDate >= startDate && itemDate <= endDate);
          return meetsRoleId && meetsDateRange && meetsAssignment;
        });
        this.filtered = true;
      }
    },
    async fetchList() {
      if (!this.list.length) {
        await http
          .get("/tasks/search")
          .then((res) => {
            this.list = res.data.result;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    socketEvent(item: any): void {
      let taskIndex = this.list.findIndex((it) => it.id == item.data[0].id);
      switch (item.event) {
        case "tasks_create":
          this.list.push(item.data[0]);
          break;
        case "task_update":
          console.log(taskIndex);
          this.list[taskIndex] = item.data[0];
          break;

        default:
          break;
      }
    },
  },
});
