import { createRouter, createWebHistory } from "vue-router";
const Layout = () => import("../layouts/default.vue");
const Kanban = () => import("../pages/kanban/index.vue");
const Calls = () => import("../pages/calls/index.vue");
const KanbanTab = () => import("../pages/kanban/kanbanTab.vue");
const TableTab = () => import("../pages/kanban/tableTab.vue");
const Branches = () => import("../pages/branches/index.vue");
const Dashboard = () => import("../pages/dashboard/index.vue");
const Clients = () => import("../pages/clients/index.vue");
const Steps = () => import("../pages/steps/index.vue");
const Regions = () => import("../pages/regions/index.vue");
const Roles = () => import("../pages/roles/index.vue");
const Users = () => import("../pages/users/index.vue");
const Services = () => import("../pages/services/index.vue");
const Sms = () => import("../pages/sms/index.vue");
// const Payments = () => import("../pages/payments/index.vue");
const Companies = () => import("../pages/companies/index.vue");
const Profile = () => import("../pages/profile/index.vue");
const Permissiondenied = () => import("../permissionDenied/index.vue");
const Login = () => import("../pages/login.vue");
const Tasks = () => import("../pages/tasks/index.vue");
const ColumnTasks = () => import("../pages/tasks/columnTab.vue");
const TableTasks = () => import("../pages/tasks/tableTab.vue");
const Marketing = () => import("../pages/marketing/index.vue");
const Accounts = () => import("../pages/accounts/index.vue");
const Transactions = () => import("../pages/transactions/index.vue");
const Counteragents = () => import("../pages/counteragent/index.vue");

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/calls",
        component: Calls,
        meta: {
          permission: 130000,
          title: "Qo'ng'iroqlar",
          pageName: "calls",
        },
      },
      {
        path: "/counteragents",
        component: Counteragents,
        meta: {
          permission: 260000,
          title: "Kontragentlar",
          pageName: "counteragents",
        },
      },
      {
        path: "/accounts",
        component: Accounts,
        meta: {
          permission: 160000,
          title: "Kassalar",
          pageName: "accounts",
        },
      },
      {
        path: "/marketing",
        component: Marketing,
        meta: {
          permission: 190000,
          title: "Marketing",
          pageName: "marketing",
        },
      },
      {
        path: "/tasks",
        component: Tasks,
        meta: {
          permission: 120000,
          title: "Topshiriqlar",
          pageName: "tasks",
        },
        children: [
          {
            path: "/tasks",
            name: "TasksColumn",
            component: ColumnTasks,
            meta: {
              permission: 120000,
              title: "Topshiriqlar",
              pageName: "tasks",
            },
          },
          {
            path: "/tasks/table",
            name: "TasksTable",
            component: TableTasks,
            meta: {
              permission: 120000,
              title: "Topshiriqlar",
              pageName: "tasks",
            },
          },
        ],
      },
      //   {
      //     path: "/tasks",
      //     component: Tasks,
      //     meta: {
      //       permission: 120000,
      //       title: "Topshiriqlar",
      //       pageName: "tasks",
      //     },
      //   },
      {
        path: "/dashboard",
        component: Dashboard,
        meta: {
          permission: 100000,
          title: "Dashboard",
          pageName: "dashboard",
        },
      },
      {
        path: "/",
        component: Kanban,
        meta: {
          permission: "all",
          title: "Ish stoli",
        },
        children: [
          {
            path: "/",
            name: "KanbanView",
            component: KanbanTab,
            meta: {
              permission: 110000,
              title: "Buyurtmalar",
              pageName: "orders",
            },
          },
          {
            path: "/table",
            name: "TableView",
            component: TableTab,
            meta: {
              permission: 110000,
              title: "Buyurtmalar",
              pageName: "orders",
            },
          },
        ],
      },
      {
        path: "/companies",
        component: Companies,
        meta: {
          permission: 170000,
          title: "Kompaniyalar",
          pageName: "companies",
        },
      },
      {
        path: "/branches",
        component: Branches,
        meta: {
          permission: 180000,
          title: "Filiallar",
          pageName: "branches",
        },
      },
      {
        path: "/clients",
        component: Clients,
        meta: {
          permission: 140000,
          title: "Mijozlar",
          pageName: "clients",
        },
      },
      {
        path: "/steps",
        component: Steps,
        meta: {
          permission: 211000,
          title: "Qadamlar",
          pageName: "steps",
        },
      },
      {
        path: "/regions",
        component: Regions,
        meta: {
          permission: 220000,
          title: "Hududlar",
          pageName: "regions",
        },
      },
      {
        path: "/roles",
        component: Roles,
        meta: {
          permission: 230000,
          title: "Ro'llar",
          pageName: "roles",
        },
      },
      {
        path: "/users",
        component: Users,
        meta: {
          permission: 240000,
          title: "Foydalanuvchilar",
          pageName: "users",
        },
      },
      {
        path: "/services",
        component: Services,
        meta: {
          permission: 250000,
          title: "Xizmatlar",
          pageName: "services",
        },
      },
      {
        path: "/sms",
        component: Sms,
        meta: {
          permission: 200000,
          title: "Sms",
          pageName: "sms",
        },
      },

      {
        path: "/transactions",
        component: Transactions,
        meta: {
          permission: 151000,
          title: "Tranzaksiyalar",
          pageName: "transactions",
        },
      },

      {
        path: "/profile",
        component: Profile,
        meta: {
          permission: "all",
          title: "Profil",
          pageName: "profile",
        },
      },
    ],
  },
  {
    path: "/login",
    component: Login,
    pageName: "login",
    meta: {
      permission: "all",
    },
  },
  {
    path: "/permissiondenied",
    component: Permissiondenied,
    meta: {
      permission: "all",
      title: "Permissiondenied",
      pageName: "Permissiondenied",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return savedPosition || { left: 0, top: 0 };
  // },
});

router.beforeEach((to, from, next) => {
  from;
  var permissionList = localStorage.getItem("permissions")?.split(",");

  if (to.meta.permission == "all") {
    next();
    return;
  } else if (permissionList) {
    let per = permissionList.includes(String(to.meta.permission));
    if (per) {
      next();
      return;
    } else {
      next("/permissiondenied");
    }
  } else {
    next("/permissiondenied");
  }
});

export default router;
