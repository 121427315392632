import { defineStore } from "pinia";
import http from "@utils/axios";

interface IItem {
  id: number | null;
  step_id: number | null;
  new_index: number | null;
}
interface IOrder {
  address_id: number;
  client_full_name: string;
  client_phone: string;
  comment: string;
  delivery_time: number | null;
  discount: string;
  id: number | null;
  order_index: number | null;
  lat: number | null;
  lng: number | null;
  number: number;
  paid: boolean;
  paid_price: number;
  payment_type: "cash" | "card";
  pickup_time: number;
  step_id: number;
  total_price: number;
  total_square: number;
  fast: boolean;
}

interface IStep {
  id: number;
  index: number;
  can_add: boolean;
  name: string;
  color: string;
}

interface IOrderList {
  list: IOrder[];
  steps: IStep[];
}

interface IState {
  positions: IItem[];
  kanban_list: IOrderList | { list: Array<IOrder>; steps: [] };
  filteredList: IOrder[];
  filtered: boolean;
}

interface INewItem {
  data: IOrder;
  event: string;
  model: string;
}

interface IFilter {
  search: string;
  regions: number[] | null;
  dateRange: string[] | null;
}

export const useKanbanStore = defineStore({
  id: "kanban",
  state: (): IState => ({
    positions: [],
    kanban_list: { list: [], steps: [] },
    filteredList: [],
    filtered: false,
  }),
  getters: {
    getPositions(): IItem[] {
      return this.positions;
    },
    getItemsByStepId(): (id: number) => IOrder[] {
      return (id: number) =>
        this.filtered
          ? this.filteredList.filter((order) => order.step_id === id)
          : this.kanban_list.list.filter((order) => order.step_id === id);
    },
    getKanbanList(): IOrderList | { list: Array<IOrder>; steps: [] } {
      return this.kanban_list;
    },
  },
  actions: {
    async setFilter(filter: IFilter) {
      if (
        Boolean(!filter.dateRange?.length) &&
        filter.search === "" &&
        Boolean(!filter.regions?.length)
      ) {
        this.filtered = false;
        return;
      } else {
        await this.fetchKanban();
        const { regions, search, dateRange } = filter;
        const startDate = dateRange?.length ? new Date(dateRange[0]) : null;
        const endDate = dateRange?.length ? new Date(dateRange[1]) : null;

        this.filteredList = this.kanban_list.list.filter((item) => {
          const itemDate = new Date(item.pickup_time);
          const meetsAddressId =
            regions?.length === 0 || regions?.includes(item.address_id);
          const meetsNumber =
            !search ||
            item.client_phone.includes(search) ||
            String(item.number).includes(search);
          const meetsDateRange =
            !startDate ||
            !endDate ||
            (itemDate >= startDate && itemDate <= endDate);
          return meetsAddressId && meetsNumber && meetsDateRange;
        });
        this.filtered = true;
      }
    },
    async fetchKanban() {
      if (!this.kanban_list.list.length) {
        await http
          .get("/konban/order/list")
          .then((res) => {
            this.kanban_list = res.data.result;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    setKanbanList(list: any): void {
      this.kanban_list = list;
    },
    setPositions(data: IItem): void {
      this.positions.push(data);
    },
    removeItem(data: IItem): void {
      const objWithIdIndex = this.positions.findIndex(
        (obj) => obj.id === data.id
      );
      if (objWithIdIndex > -1) {
        this.positions.splice(objWithIdIndex, 1);
      }
    },
    setKanbanListItem(item: INewItem): void {
      var audio = new Audio("/ting.mp3");
      audio.play();
      let orderIndex = this.kanban_list.list.findIndex(
        (it) => it.id == item.data.id
      );
      let filterOrderIndex = this.filteredList.findIndex(
        (it) => it.id == item.data.id
      );
      switch (item.event) {
        case "order_create":
          item.data.fast
            ? this.kanban_list.list.unshift(item.data)
            : this.kanban_list.list.push(item.data);
          item.data.fast
            ? this.filteredList.unshift(item.data)
            : this.filteredList.push(item.data);
          break;
        case "order_update":
          this.kanban_list.list[orderIndex] = item.data;
          this.filteredList[filterOrderIndex] = item.data;
          break;
        case "order_change_step":
          this.kanban_list.list[orderIndex] = item.data;
          this.filteredList[filterOrderIndex] = item.data;
          console.log(
            item.data.step_id,
            this.kanban_list.list[orderIndex].step_id
          );
          break;
        case "order_carpet_create":
          this.kanban_list.list[orderIndex] = item.data;
          this.filteredList[filterOrderIndex] = item.data;
          this.filteredList[filterOrderIndex] = item.data;
          break;
        case "order_paid_price":
          this.kanban_list.list[orderIndex] = item.data;
          this.filteredList[filterOrderIndex] = item.data;
          break;

        default:
          break;
      }
    },
    setOrderIndex(data: IItem): void {
      this.kanban_list.list.find((it) => {
        if (it.id == data.id) {
          it.order_index = data.new_index;
        }
      });
    },
  },
});
