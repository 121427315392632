<script setup lang="ts">
import { onMounted } from "vue";

import { useAuthStore } from "@store/auth";
import router from "./router";

const authStore = useAuthStore();

onMounted(() => {
  if (localStorage.getItem("theme")) {
    authStore.setTheme(localStorage.getItem("theme") as "dark" | "light");
  } else {
    if (window.matchMedia) {
      const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
      if (darkModeQuery.matches) {
        authStore.setTheme("dark");
      } else {
        authStore.setTheme("light");
      }
    }
  }
  if (localStorage.getItem("token")) {
    authStore.getMe();
  } else {
    router.push("/login");
  }
});
</script>

<template>
  <RouterView />
</template>
