import { defineStore } from "pinia";
import http from "@utils/axios";
import router from "../router";

interface IState {
  expires_in: string;
  token_prefix: string;
  token: string;
  user: IUser | null;
  theme: "dark" | "light";
  loading: boolean;
}

interface IUser {
  avatar: string;
  branch_id: number;
  full_name: string;
  id: number;
  permissions: string[];
  phone: string;
  role_id: number;
  role_name: string;
  dashboard_settings: any;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): IState => ({
    expires_in: localStorage.getItem("expires_in") ?? "",
    token_prefix: localStorage.getItem("token_prefix") ?? "",
    token: localStorage.getItem("token") ?? "",
    user: null,
    theme: localStorage.getItem("theme") as "dark" | "light",
    loading: false,
  }),

  actions: {
    setTheme(value: "dark" | "light") {
      this.theme = value;
      localStorage.setItem("theme", value);
      if (value === "dark") {
        document.getElementById("html")!.classList.add("dark");
      } else {
        document.getElementById("html")!.classList.remove("dark");
      }
    },
    async login(payload: any) {
      this.token = payload.access_token;
      localStorage.setItem("token", payload.access_token);
      this.token_prefix = payload.token_type;
      localStorage.setItem("token_prefix", payload.token_type);
      this.expires_in = payload.expires_in;
      localStorage.setItem("expires_in", payload.expires_in);
      await this.getMe();
      await router.push("/");
      this.loading = false;
    },
    async getMe() {
      await http
        .get("/auth/user")
        .then((res) => {
          this.user = res.data.result;
          localStorage.setItem("permissions", res.data.result.permissions);
          localStorage.setItem("branch_id", res.data.result.branch_id);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    logOut() {
      http
        .post("/auth/logout")
        .then(() => {
          localStorage.clear();
          this.token = "";
          this.expires_in = "";
          this.token_prefix = "";
          this.user = null;
          router.push("/login");
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  getters: {
    getTheme(): "dark" | "light" {
      return this.theme;
    },
    getTokenPrefix(): string {
      return this.token_prefix;
    },
    getToken(): string {
      return this.token;
    },
    getUser(): IUser | null {
      return this.user;
    },
  },
});
