import Echo from "laravel-echo";
import socketIo from "socket.io-client";

let globalSocket: any = null;
export const startSocketConnection = async () => {
  let access_token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  if (!globalSocket && access_token) {
    const token = `Bearer ${access_token}`;
    globalSocket = new Echo({
      client: socketIo,
      broadcaster: "socket.io",
      transports: ["websocket"],
      host: "wss://newapi.gilam.biz:6004",
      auth: {
        headers: {
          Authorization: token,
          // lang: "ru",
        },
      },
    });
  }
  return globalSocket;
};
