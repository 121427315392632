import "./assets/styles/main.scss";
import { createApp } from "vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import router from "./router";
import App from "./App.vue";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "./style.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import uzUz from "element-plus/dist/locale/uz-uz.mjs";
import { startSocketConnection } from "./utils/socket";
import { useKanbanStore } from "./store/kanban";
import { useTaskStore } from "./store/tasks";

startSocketConnection().then((socket: any) => {
  socket
    .private(`tasksnotify.${localStorage.getItem("branch_id")}`)
    .listen(".tasksnotify", (res: any) => {
      useTaskStore().socketEvent(res);
    });
});

startSocketConnection().then((socket: any) => {
  socket
    .private(`notify.${localStorage.getItem("branch_id")}`)
    .listen(".notify", (res: any) => {
      useKanbanStore().setKanbanListItem(res);
    });
});

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.directive("can", {
  created: (el, binding) => {
    var permissionList = localStorage.getItem("permissions")?.split(",");

    if (
      permissionList &&
      permissionList != null &&
      permissionList != undefined
    ) {
      let per = permissionList.includes(String(binding.value));
      //   console.log(per, binding.value, el);

      if (!per) {
        setTimeout(() => {
          el.remove();
        }, 1);
      }
    }
  },
  updated: (el, binding) => {
    var permissionList = localStorage.getItem("permissions")?.split(",");
    if (
      permissionList &&
      permissionList != null &&
      permissionList != undefined
    ) {
      let per = permissionList.includes(String(binding.value));
      //   console.log(per, binding.value, el);
      if (!per) {
        setTimeout(() => {
          el.remove();
        }, 1);
      }
    }
  },
});

app.use(createPinia());
app.use(ElementPlus, {
  locale: uzUz,
});
app.use(router);

app.mount("#app");
